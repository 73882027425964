@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: 'Quicksand', sans-serif;
}

.home {
	font-family: 'Quicksand', sans-serif;
}

.project_title {
	font-family: 'Cabin Sketch', cursive;
}

.resume {
	font-family: 'Roboto Slab', serif;
}

.navbar {
	font-family: 'Quicksand', sans-serif;
}

.visible {
	opacity: 1;
}

.unloaded {
	opacity: 0;
	transition: opacity 1s;
}

.loaded {
	opacity: 1;
}
